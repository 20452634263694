import React from 'react';
import '../App.css';
import { privacyContent } from '../data/support';

function PrivacyPage() {
    return (
        <div className="Privacy">
            <div dangerouslySetInnerHTML={{ __html: privacyContent }} />
        </div>
    );
}

export default PrivacyPage;