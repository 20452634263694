import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import { SocialIcon } from 'react-social-icons';

function SupportPage() {
    return (
        <div className="SupportMain">
            <div className="Support">
                <div style={{
                    textAlign: 'justify',
                    color: 'white'
                }}>
                    i-Azyq.kz - это интернет-магазин продуктов c доставкой по г. Шымкент.
                </div>
                <div style={{
                    textAlign: 'justify',
                    color: 'white',
                    marginTop: '20px'
                }}>
                    Мы небольшая команда единомышленников, объединившихся с целью создания интернет-магазина с минимальной выгодой для себя и максимальной выгодой для клиентов.
                </div>
                <div style={{
                    textAlign: 'justify',
                    color: 'white',
                    marginTop: '20px'
                }}>
                    Наша цель — сэкономить время и деньги нашим клиентам. Мы не ставим цели заработать как можно больше денег, нам достаточно стабильности. Поэтому, наш девиз: чем больше заказов, тем ниже цены.
                </div>
                <div style={{
                    textAlign: 'justify',
                    color: 'white',
                    marginTop: '20px'
                }}>
                    Мы работаем только с проверенными поставщиками, поэтому гарантируем качество всех товаров. Ассортимент товаров обновляется и расширяется каждый день.
                </div>
                <div style={{
                    textAlign: 'justify',
                    color: 'white',
                    marginTop: '20px'
                }}>
                    {`Если у Вас есть вопросы и предложения, напишите нам на почту `}
                    <a style={{ color: 'lawngreen' }} href={`mailto:i.azyq.kz@gmail.com`}>i.azyq.kz@gmail.com</a>;
                </div>
                <div style={{
                    textAlign: 'justify',
                    color: 'white',
                    marginTop: '20px'
                }}>
                    Мы в социальных сетях:
                </div>
                <div style={{
                    marginTop: '20px',
                }}>
                    <SocialIcon url="https://www.instagram.com/iazyq.kz/" />
                    <SocialIcon style={{ marginRight: 20, marginLeft: 20 }} url="https://telegram.me/i_azyq_kz" />
                    <SocialIcon url="https://wa.me/77710015001" network="whatsapp"/>
                </div>
            </div>
        </div>
    );
}

export default SupportPage;